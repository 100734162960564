// Mixins
@mixin antialiased {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
}


img {
	max-width: 100%;
	height: auto;
}

a:focus, a:active {
	outline: none;
}

.container-max-width {
	max-width: 1100px;
	position: relative;
//	z-index: 1;
	
	> .row.base {
		max-width: 620px;
		background: white;
		border-left: 15px solid $brand-primary;
		margin-top: 50px;
		margin-bottom: 50px;
		
		// Cols same height
		@media (min-width: $screen-sm-min) {
			display: table;
			
			> main, > aside {
				display: table-cell;
				float: none;
			}
		}
	}
}

// Main 
.row.base main {
	padding: 30px 25px;
	
	.logo {
		margin-bottom: 30px;
		float: left;
		@media (min-width: $screen-sm-min) {
			float: none;
		}
	}
	.menu-btn {
		float: right;
		margin-left: 15px;
		margin-right: -15px;
	}
}

// Nav 
.row.base aside {
	background: $gray-lightest;
	font-family: $headings-font-family;
	font-size: 14px;
	padding-left: 0;
	padding-right: 0;
	
	nav {
		padding-bottom: 25px;
	}
	
	ul {
		padding-left: 20px;
		padding-right: 20px;
		li a {
			color: $gray-base;
			@include antialiased;
			margin-top: 7px;
			display: block;
			position: relative;
			transition: color 0.3s ease-out;
			
			&:hover {
				color: $brand-primary;
			}
		}
		&.lower-menu {
			border-top: 4px solid darken($gray-lightest, 5%);
			margin-top: 6px;
			padding-top: 8px;
			
			li a {
				color: lighten($gray-base, 40%);
				font-size: 13px;
				margin-top: 2px;
			}
		}
		
		// arrow
		li {
			position: relative;
			
			&:before {
				width: 0; 
				height: 0; 
				border-top: 13px solid transparent;
				border-bottom: 13px solid transparent; 
				border-right:13px solid $gray-lightest;
				content: " ";
				position: absolute;
				left: 0;
				transition: all 0.2s ease-out;
			}
			&.active, &:hover, &.current-menu-item {		
				&:before {
					left: -33px;
				}
				a {
					color: $brand-primary;
				}
			}
		}
	}
}

// Pushy Nav
nav.pushy {
	font-family: $headings-font-family;
	font-size: 14px;
	padding: 15px;
	li a {
		color: $gray-base;
		@include antialiased;
		margin-top: 7px;
	}
	li.active a, li.current-menu-item a {
		color: $brand-primary;
	}
}



// Typo
h1, h2, h3 {
	@include antialiased;
}

article {
	clear: both;
	body.home & p:first-of-type {
		font-size: 15px;
		font-weight: 300;
	}
}

// Forms
form {
	max-width: 46em;
	margin-top: 30px;
	
	.form-control {
		box-shadow: none;
	}
	
	label {
		font-weight: 400;
	}
	span.required {
		color: $brand-primary;
	}
}
body, html{
    height: 100%;
}

#slider {
	position: fixed;
	z-index: -1;
	 width:100%;
    height:100% !important;
    top:0;
    left:0;
	
	.bx-viewport, .bx-wrapper{
    position:relative;
    width:100%;
    height:100% !important;
    top:0;
    left:0;
}
.bxslider, .bxslider li{
    height: 100% !important;;
}
.bxslider li{
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
	
	&.lazyloaded {
		opacity:0;
		animation:fadeIn ease-in;
		animation-fill-mode:forwards;
		animation-duration:0.6s;
//		animation-delay: 0.3s;
	}
	
	@keyframes fadeIn { 
		from { opacity:0; } 
		to { opacity:1; } 
	}
}
.bx-wrapper .bx-viewport{
    border: none !important;
}
}